import { computed, ref } from 'vue'
import router from '@/router'

const client = ref({})
const activity = ref(false)

const userState = () => {
  const setUser = (data) => {
    client.value = data
    activity.value = true
  }
  const clearUser = () => {
    client.value = {}
    activity.value = false
    router.push('/')
  }
  return {
    client: computed(() => client.value),
    activity: computed(() => activity.value),
    setUser,
    clearUser
  }
}

export default userState
