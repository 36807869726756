<template>
  <div class="blockAddtext">
         <div class="button nocopy" @click="viewNotes = !viewNotes">{{ !viewNotes ? 'Добавить заметку' : 'Закрыть' }}</div>
         <div class="textManger" v-if="viewNotes">
         <textarea v-model="notesText"></textarea>
         <div class="button nocopy record" :class="{voiceActivity:voiceActivity}" @click="!voiceActivity ? voice() : stopvoice()">{{!voiceActivity ? 'Записать' : 'Остановить'}} <div class="circle"></div></div>   
         <div class="button nocopy" @click="saveNotes()">Сохранить</div>
        </div>
  </div>  
  <div class="blockNotes" v-if="!!noteRever.length">
    <div class="oneNotes" v-for="(one,index) of noteRever" :key="one._id">
      <div class="blockInfos">
        <p>{{ goodData(one.createAt) }}</p>
        <div class="manages">
          <p>{{one.manager.firstname}} {{one.manager.lastname}}</p>
        </div>
      </div>
      <p>{{ one.text }}</p>
      <div class="delete" @click="deleteNotes(index)"><p>Удалить</p></div>
    </div>
  </div>
  <div class="noOrders" v-else>
    <p>Заметок нет</p>
  </div>

</template>

<script setup>
import { client,saveUser,updateLogin } from '@/controlles/client'
import { user } from '@/controlles/auth'
import { ref,computed } from 'vue'
import {goodData} from '@/controlles/general'

  const notesText = ref('')
  const viewNotes = ref(false)
  const voiceActivity = ref(false)
  const recognition = ref(null)
  
  const voice = async () => {
        try{
        recognition.value = new window.webkitSpeechRecognition();
        recognition.value.lang = 'ru-RU'
        recognition.value.continuous = false
        recognition.value.interimResults = false        
        voiceActivity.value = true
        recognition.value.addEventListener("result", event => { 
          let result = ''
          for(let i = 0;i <= event.resultIndex; i ++ ) {
            if (event.results[i].isFinal){ 
              result += event.results[i][0].transcript
            }else{
              console.log(event.results[event.resultIndex][0].transcript)
            }
          }
          notesText.value += result + ' ';  
        });
        recognition.value.addEventListener("end", stopvoice);
        recognition.value.start();
      }catch(e){console.log(e)}
    }

  const stopvoice = () => {
            recognition.value.stop();
            voiceActivity.value = false
  }

  const saveNotes = async () => {
    try{
     
      if(!client.value.notes){client.value['notes'] = []}
      client.value.notes.push({text:notesText.value,manager:user.value._id})
      notesText.value = ''
      await saveUser()
      await updateLogin(client.value.phone, 'phone')
      viewNotes.value = false
      voiceActivity.value = false

    }catch(e){
      console.log(e)
    }
  }

  const deleteNotes = async (index) => {
    
    client.value.notes.splice(index,1)
    await saveUser()
    await updateLogin(client.value.phone, 'phone')
  }

  const noteRever = computed(() =>{
   
    if(client.value.notes){
     
      if(client.value.notes.length > 0){
        return client.value.notes.sort().reverse()
      }
      return []
    } 
    
    return []
    }
  )

</script>

<style scoped>

.blockAddtext{
margin-top: 20px;
margin-bottom: 20px;
}

.blockAddtext textarea{

margin-top: 20px;
padding: 10px;
width: calc(100% - 25px);
min-height: 150px;
margin-bottom: 20px;
}
.textManger{

}

.record{
display: grid;
grid-template-columns: auto auto;
justify-content: center;
gap: 10px;
margin-bottom: 20px;
}

.record .circle {
width: 30px;
height: 30px;
border-radius: 100%;
background: red;
transition: 500ms;

}

.voiceActivity .circle{
  animation: heartbeat 1s ease-in-out infinite both;

}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.81);
            transform: scale(0.81);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.88);
            transform: scale(0.88);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.77);
            transform: scale(0.77);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

.noOrders{
display: grid;
align-items: center;
justify-items: center;
height: 70vh;

}

.oneNotes{
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px;
    width: calc(100% - 20px);
    background: var(--white);
    margin-bottom: 10px;
}

.blockInfos{
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 10px;
}
.manages{


}

.delete{
    display: grid;
    width: 100%;
    justify-items: end;
    margin: 10px 0px;
}
.delete p {
    text-decoration: underline;
    text-underline-offset: 2px;
}
</style>
