export default [
  {
    path: '/catalog/sale',
    name: 'catalog-sale',
    component: () => import('../views/catalog/sale.vue')
  },
  {
    path: '/catalog',
    name: 'catalog-index',
    component: () => import('../views/catalog/index.vue')
  },
  {
    path: '/catalog/:slug',
    name: 'catalog-slug',
    component: () => import('../views/catalog/slug.vue')
  }

]
