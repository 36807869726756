<template>
    <div class="oneOrder" @click="getOneOrder()">
        <div class="title botder grid">
            <p>{{ o.ordernumber }} {{ o.crmnumber ? ` / ${o.crmnumber}` : '' }}</p>
            <p class="right">{{ orderInfoStatus[o.status] }}</p>
        </div>
        <div class="grid">
            <p>Дата:</p>
            <p class="right">{{ goodData(o.createdAt) }}</p>
        </div>
        <div class="grid">
            <p>Канал продажи:</p>
            <p class="right">{{ channelInfo[o.channel] }}</p>
        </div>
        <div class="grid">
            <p>Сумма:</p>
            <p class="right">{{ viewPriceAll }}</p>
        </div>
        <div class="grid">
            <p>Оплата:</p>
            <p class="right">{{ payMentsGood ? 'Оплачен' : 'Не оплачен' }}</p>
        </div>
        <div class="grid">
            <p>Товаров:</p>
            <p class="right">{{o.items.length}}</p>
        </div>
    </div>
    <transition name="slimeright">
        <slotokno :close="() => oneOrder = {}" :text="viewTextName" v-if="viewOknoOrder">
         <component :is="openOrder"  :d="oneOrder"/>
        </slotokno>
    </transition>

</template>

<script setup>

import {orderInfoStatus,channelInfo,goodData} from '@/controlles/general'
import {computed,ref } from 'vue'
import {activity} from '@/controlles/client'
import { channelRole } from '@/controlles/auth'
import allorder from '@/components/order/allorder.vue'
import returnorder from '@/components/order/returnorder.vue'
import sborka from '@/components/order/sborka.vue'
import axios from '@/utils/axios'
const url = `${process.env.VUE_APP_API_BD}`
const {o:o,options:options} = defineProps(['o','options'])
const oneOrder = ref({})
const viewTextName = ref('')
const viewPriceAll = computed(() => {
    let price = 0
    price = o.items.reduce((x,m) => x += m.price*m.quantity,0)
    if(o.delivery.length > 0){price = price + o.delivery[0].price}
    return price
})
const payMentsGood = computed(() => {
    var pament = false
    if(o.payment.length > 0){
    var fullP = 0
    for(let one of o.payment){
            if (one.status == "paid") {
                pament = true;
                fullP = fullP + one.price;
            }
     }
     if(viewPriceAll.value !== fullP){
        pament = false
     }
    }
    return pament
})
const openOrder = computed(() => {
    if(channelRole.value == 'warehouse'){
        if(options == 'return'){
            viewTextName.value = `Закрыть возврат ${o.ordernumber} ${o.crmnumber ? ` / ${o.crmnumber}` : ''}`
            return returnorder
        }
        if(options == 'default'){
            viewTextName.value = `Закрыть сборку ${o.ordernumber} ${o.crmnumber ? ` / ${o.crmnumber}` : ''}`
            return sborka
        }
    }
    if(channelRole.value == 'shop'){
        if(options == 'return'){
            viewTextName.value = `Закрыть возврат ${o.ordernumber} ${o.crmnumber ? ` / ${o.crmnumber}` : ''}`
            return returnorder
        }
        if(options == 'default'){
            viewTextName.value = `Закрыть заказ ${o.ordernumber} ${o.crmnumber ? ` / ${o.crmnumber}` : ''}`
            return allorder
        }
    }
    viewTextName.value = `Закрыть заказ ${o.ordernumber} ${o.crmnumber ? ` / ${o.crmnumber}` : ''}`
    return allorder
})

const getOneOrder = async () => {
    try{
        let oneOrderGet = await axios.post(`${url}/bd`,{
            collection:'order',
            filter:{_id:o._id},
            populate:[
                {path:'contragent',select:['firstname','lastname','phone','avatar']},
                {path:'items.productid',select:['images']}
            ]
        })
        oneOrder.value = oneOrderGet.data[0]
    }catch(e){
    }
}

const viewOknoOrder = computed(() => !!Object.entries(oneOrder.value).length)

</script>

<style scoped>
.oneOrder{
    background: var(--white);
    padding:10px;
    width: calc(100% - 20px);
}
.grid{
    display: grid;
    grid-template-columns: 1fr auto;
}

.botder {
    border-bottom: 1px solid var(--blackgrey);
}

.title{
    margin-bottom: 10px;
}

.title p:nth-child(1){
    font-weight: 300;
    font-size: 14px;
}
.right{
    text-align: right;
}
</style>