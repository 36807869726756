<template>

                        <div class="Navigations">
                            <div class="icons_svg clickbutton" v-for="(m,index) of menu" :key="'menu' + index" @click="gotoMenu(m.path)">
                                <p>{{m.name}}</p>
                            </div>
                            <div class="icons_svg clickbutton red" @click="exitAuth()"><p>Выход</p></div>
                        </div>

</template>

<script setup>

import role from '@/role'
import { useRoute,useRouter } from 'vue-router'
import { exitUser } from '@/controlles/auth'
import {headMenu} from '@/controlles/general'

    const r = useRouter()
    const rt = useRoute()
    const menu = role.headermenu.getMenuHeader()
    const exitAuth = () => {
      exitUser()
      location.reload()
    }
    const gotoMenu = (path) => {
        r.push({name:path})
        if(path == rt.name){
            headMenu.value = false
        }
    }

</script>

<style scoped>

.Navigations{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    justify-items: center;
    gap: 5px;
    margin-top: 20px;

}

.icons_svg{
    display: grid;
    gap: 5px;
    justify-items: center;
    align-items: center;
    border: 1px solid rgb(206 206 206 / 43%);
    padding: 5px;
    width: calc(100% - 10px);
    background: var(--white);
    height: 100px;
}

.icons_svg p{
    font-weight: 200;
    color: var(--blue);
    font-size: 22px;
    text-align: center;
}

.icons_svg .favorites{
       position: absolute;
    width: 25px;
    height: 25px;
    font-size: 12px;
    margin-top: -101px;
    font-weight: 300;
    margin-left: 18%;
}
.red{
    background: var(--red);
}

.red p {
    color:var(--white)
}
</style>
