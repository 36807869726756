import axios from '@/utils/axios'
import { ref } from 'vue'
const categoryProduct = ref([])

const getCategoryAll = async () => {
  try {
    const data = await axios.get('https://api.ultimatum.store/shop/category/all')
    categoryProduct.value = data
  } catch (e) {
    console.log(e)
  }
}

export {
  getCategoryAll,
  categoryProduct
}
