<template>

                        <clientitem
                            class="twogrid"
                            :firstname = 'client.firstname || "нет"'
                            :lastname = 'client.lastname || "нет"'
                            :photo = 'client.avatar'
                            :phone = 'client.phone !== null ? client.phone : "нет номера"'
                         />

                        <div class="information">
                            <div class="leftinfo">
                                <div class="infoBlocs" v-if="false">
                                    <p>Покупок:</p><span>{{client.total}}</span>
                                </div>
                                <div class="infoBlocs">
                                    <p>Бонусов</p><span>{{client.bonus}}</span>
                                </div>

                                <div class="infoBlocs">
                                    <p>Аванс:</p><span>{{client.avans}}</span>
                                </div>
                                <div class="infoBlocs">
                                    <p>Ultimatum Select:</p><span>{{client.ad_deposit}}</span>
                                </div>
                            </div>
                            <div class="rightinfo">

                            </div>

                        </div>
                        <div class="Navigations">
                            <div class="icons_svg clickbutton" v-for="(m,index) of menu" :key="'menu' + index" @click="viewUser.module = m.path">
                                <p>{{m.name}}</p>
                                <span v-if="m.data">{{m.data}}</span>
                            </div>

                            <div class="icons_svg clickbutton red" @click="clearUser(),oknoUser(false)"><p>Закрыть</p></div>
                        </div>
        <transition name="slimeright">
        <slotokno :close="() => viewUser.module = 'dashbord'" :text="'Закрыть профиль'" v-if="viewUser.module == 'clientProfile'"><profile /></slotokno>
        </transition>
        <transition name="slimeright">
        <slotokno :close="() => viewUser = {module:'dashbord',view:true,options:null}" :text="viewUser.options ? 'Закрыть заказы на возврат' : 'Закрыть заказы'" v-if="viewUser.module == 'clientOrders'">
        <Suspense><orders :options="viewUser.options"/></Suspense>
        </slotokno>
        </transition>
        <transition name="slimeright">
        <slotokno :close="() => viewUser.module = 'dashbord'" :text="'Закрыть заметки'" v-if="viewUser.module == 'clientNotes'">
        <Suspense><notes /></Suspense>
        </slotokno>
        </transition>
        <transition name="slimeright">
        <slotokno :close="() => viewUser.module = 'dashbord'" :text="'Закрыть возврат'" v-if="viewUser.module == 'clientReturn'">
        <Suspense><returnorder /></Suspense>
        </slotokno>
        </transition>

</template>

<script setup>

import role from '@/role'
import { useRouter } from 'vue-router'
import { clearUser, client, clientInfo, viewUser, oknoUser } from '@/controlles/client'
import { validPhones } from '@/controlles/general'
import clientitem from '@/components/client/clientitem.vue'
import profile from '@/components/client/profile.vue'
import orders from '@/components/client/orders.vue'
import notes from '@/components/client/notes.vue'
import returnorder from '@/components/client/returnorder.vue'

    const r = useRouter()
    const menu = role.headermenu.getMenuClient()
    
 
</script>

<style scoped>

.Navigations{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    justify-items: center;
    gap: 5px;
    margin-top: 20px;

}

.Navigations span{
    font-weight: 300;
    color: var(--red);
    font-size: 24px;
    margin-top: -40px;
}

.icons_svg{
    display: grid;
    gap: 5px;
    justify-items: center;
    align-items: center;
    border: 1px solid rgb(206 206 206 / 43%);
    padding: 5px;
    width: calc(100% - 10px);
    background: var(--white);
    height: 100px;
}

.icons_svg p{
    font-weight: 200;
    color: var(--blue);
    font-size: 22px;
    text-align: center;
}

.icons_svg .favorites{
    position: absolute;
    width: 25px;
    height: 25px;
    font-size: 12px;
    margin-top: -101px;
    font-weight: 300;
    margin-left: 18%;
}
.red{
    background: var(--red);
}

.red p {
    color:var(--white)
}

.information{
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px;
    width: calc(100% - 20px);
    background: var(--white);
    margin: 10px 0px -10px;
    border-radius: 5px;
    box-shadow: var(--boxshadowlight);
    align-items: center;
}
.infoBlocs{
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}
.infoBlocs p {
    font-size:18px;
    font-weight: 200;
    color: var(--blue);
}
.infoBlocs span {
    font-size: 18px;
    font-weight: 300;
    color: var(--red);
}

</style>
