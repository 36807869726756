<template>
    <div>
          <clientinfo :c="d.contragent"/>
          <div class="productOrder">
            <orderitem :d="d" v-for="d of d.items"/>
          </div>
    </div>
    <div class="navigation">
         <div class="button" v-if="d.status == 'extradition'" @click="vydatZakaz()">ВЫДАТЬ</div>
    </div>
</template>

<script setup>
import clientinfo from "@/components/order/clientinfo.vue";
import orderitem from "@/components/order/orderitem.vue"
import axios from '@/utils/axios'
import { notSet } from '@/controlles/general'
const {d:d} = defineProps(['d'])

const vydatZakaz = async () => {
          try{
          await axios.post('https://api.ultimatum.store/order/sendcrm', { id: d._id })
          await axios.post('https://api.ultimatum.store/bd/updatedocument', { collection: 'order', _id: d._id, set: { $set: { status: 'successfully' } } })
          await axios.post('https://api.ultimatum.store/crm/successfullyshop', { id: d._id })
          notSet('Заказ выдан')
          d.status = 'successfully'
          }catch(e){
            console.log(e)
            setSuc('Ошибка оформления', 'error')
          }
}

</script>

<style scoped>
.productOrder{
    margin-top:20px;
    display: grid;
    gap:20px;
}
.navigation{
    margin-top:20px;
}
</style>