export default [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/index.vue')
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/auth.vue'),
    meta: {
      layout: 'AppLayoutAuth',
      auth: true
    }
  }

]
