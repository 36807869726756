<template>

    <div class="fishki">
        <div class="title">
            <p>Добавить реферала:</p>
            <div class="poisk">
            <input type="text" v-model="text" placeholder="Телефон или фамилия">
            <div class="button" @click="searChview()">найти</div>
            <p v-if="order.refferal !== null" style="text-align: center;
    margin-top: 10px;
    color: var(--green);">Реферал добавлен</p>
            </div>
        </div>
      
    </div>
    <transition name="slimeright">
          <div class="okno_full" v-if="arrayClient.length > 0">
          <div class="headnot" @click="arrayClient = []"><h5>Закрыть cписок</h5></div>
          <div class="conents_okno">
                <clientitem
                v-for="(one,index) of arrayClient"
                :key="'oneUser' + index"
                @click="setClient(one)"
                :firstname = 'one.firstname || "нет"'
                :lastname = 'one.lastname || "нет"'
                :photo = 'one.avatar'
                :phone = 'one.phone !== null ? one.phone : "нет номера"'
                />
          </div>
          </div>
    </transition>

</template>

<script setup>
import { ref } from 'vue'
import { notSet } from '@/controlles/general'
import axios from 'axios'
import clientitem from '@/components/client/clientitem.vue'
import {order} from '@/controlles/basket/basket'
const text = ref(null)
const arrayClient = ref([])

const searChview = async () => {
    try{
    if(text.value == null || text.value.length < 4){
        notSet('Введите минимум 4 символа', 'error')
        return
    }
    var getNames = await axios.post(`https://api.ultimatum.store/arm/client/refferal`,{text:text.value})
    if(getNames.length > 0){
        arrayClient.value = getNames
    }else{
        notSet('Клиент не найден', 'error')
    }
    }catch(e){
        console.log(e)
    }

}

const setClient = (one) => {
        order.refferal = one._id
        arrayClient.value = []
        notSet('Реферал добавлен', 'good')

}


</script>

<style scoped>
.fishki{
   

}
.title{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding: 10px 0px;
}
.title p{

    font-weight: 300;
    color: var(--black);
    font-size: 18px;

}

</style>
