import { defineAsyncComponent } from "vue";
const bLock = () => {
    return {
        searchOrder: defineAsyncComponent(() => import("./searchOrder.vue")),
        vydatOrderShop: defineAsyncComponent(() => import("./vydatOrderShop.vue")),
    }
}

export {
    bLock
}