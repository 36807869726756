<template>
    <div class="top">
        <div class="twoBlockIngos">
        <clientinfo :c="d.contragent"/>
        <p class="nameChannel">{{bookPay[d.channel]}}</p>
        </div>
        <h2 class="centeproduct">Товары</h2>
        <div class="blockRetunProduct">
            <div class="oneProduct" v-for="one of d.items" :key="one._id" :class="{activityblock:proverKas(one._id)}">
                <img :src="`https://api.ultimatum.store/image/${one.productid.images[0]}?quality=100&width=160&format=jpeg`" @click="addRetun(one)">
                <div class="productName" @click="addRetun(one)">
                    <p>{{ one.name }}</p>
                    <p>Кол-во:{{ one.quantity }}</p>
                    <p>Стоимость:{{ one.price }}</p>
                    <p>Общая стоимость:{{ one.price*one.quantity }}</p>
                </div>
                <transition name="slimeright">
                <div class="menuRetun" v-if="proverKas(one._id)">
                    <div class="inputtitle">
                        <p>Кол-во:</p>
                        <input 
                            type="number" 
                            v-model="returnProduct.items.find(x => x.position == one._id).quantity"
                            min="1"
                            :max="one.quantity"
                            @change="kVozvraty()"
                        >
                    </div>
                    <div class="inputtitle">
                        <p>Тип возврата:</p>
                        <select v-model="returnProduct.items.find(x => x.position == one._id).quality">
                            <option value="new">Новый</option>
                            <option value="bu">Б/У</option>
                        </select>
                    </div>
                    <div class="button" @click="openViewFotoAndCommet(one._id,'photo')">Фотография ({{ returnProduct.items.find(x => x.position == one._id).images.length }})</div>
                    <div class="button" @click="openViewFotoAndCommet(one._id,'comments')">Комментарий ({{ returnProduct.items.find(x => x.position == one._id).comments.length > 0 ? 'есть' : 'нет' }})</div>
                </div>
                </transition>
            </div>
        </div>
        <transition name="slimeright">
        <div class="itogiVozvrata" v-if="returnProduct.items.length > 0">
            <h2 class="centeproduct">К возврату:</h2>
            <div class="textVozvrat" v-for="(one,index) of kVozvraty()" :key="'PayS' + index">
            <p>{{bookPay[one.service]}} ({{ bookPay[one.entity] }}):</p>
            <p>{{ one.price }}</p>
            </div>

            <div class="blockUpravlenie">
                <div class="blockDeystvie">
                  <div class="twoblocks">
                        <div class="customCheckbox" :class="{customCheckboxActivity:ds.returnpay}"
                        @click="ds.returnpay = !ds.returnpay"></div>
                        <p>Оплата возращена</p>
                  </div>

                  <div class="twoblocks">
                        <div class="customCheckbox" :class="{customCheckboxActivity:ds.prinyat}"
                        @click="ds.prinyat = !ds.prinyat"></div>
                        <p>Товар принят</p>
                  </div>

                  <div class="twoblocks">
                        <div class="customCheckbox" :class="{customCheckboxActivity:ds.avans}"
                        @click="ds.avans = !ds.avans"></div>
                        <p>Начислить аванс</p>
                  </div>
                </div>
            </div>
        
        
        </div>
        </transition>
        <transition name="slimeright">
            <div class="button nocopy buttonsdown" v-if="returnProduct.items.length > 0">Готово</div>
        </transition>

        <transition name="slimeright">
        <slotokno :close="() => closeViewFotoAndCommet()" :text="'Закрыть фотография'" v-if="viewFotoAndComment.view && viewFotoAndComment.type == 'photo'">
            <div class="addPhotos">
                <div class="onePhoto" v-for="(one,index) of returnProduct.items.find(x => x.position == viewFotoAndComment.id).images">
                    <img :src="`https://api.ultimatum.store/images/${one}`">
                    <p clas="photoDelete" @click="returnProduct.items.find(x => x.position == viewFotoAndComment.id).images.splice(index,1)">Удалить</p>
                </div>
                <input
                type="file"
                multiple="multiple"
                @change="downloadfile"
                id="file_one_images"
                class="nodisplay"
                accept="*"
            />
           <label for="file_one_images">
                <div class="onePhotoAdd">+</div>
           </label>
            </div>
        </slotokno>
        </transition>
        <transition name="slimeright">
        <slotokno :close="() => closeViewFotoAndCommet()" :text="'Закрыть коментарий'" v-if="viewFotoAndComment.view && viewFotoAndComment.type == 'comments'">
            <div class="comments">
            <textarea v-model="returnProduct.items.find(x => x.position == viewFotoAndComment.id).comments"></textarea>
            <recorder v-model:gettext="returnProduct.items.find(x => x.position == viewFotoAndComment.id).comments" />
            </div>
        </slotokno>
        </transition>
        
    </div>
</template>

<script setup>
import clientinfo from "@/components/order/clientinfo.vue";
import {reactive, ref,watch} from 'vue'
import recorder from '@/components/general/recorder.vue'
import {bookPay} from '@/utils/book'
import {newObject,addImage} from '@/controlles/general'
const ds = reactive({
      returnpay: true,
      prinyat: true,
      avans: false
})

watch(ds, () => {
    if(ds.avans){
        ds.returnpay = false
    }
    if(ds.returnpay){
        ds.avans = false
    }

})

const {d:d} = defineProps(['d'])
const returnProduct = ref({order:d._id,items:[],ordernumber:`${d.ordernumber}R`,channel:`${d.channel}`})
const viewFotoAndComment = ref({
    view:false,
    type:null,
    id:null
})
const closeViewFotoAndCommet = () => {
    viewFotoAndComment.value = {
        view:false,
        type:null,
        id:null
    }
}

const openViewFotoAndCommet = (id,type) => {
    viewFotoAndComment.value = {
        view:true,
        type:type,
        id:id
    }
}

const downloadfile = async (e) => {
      var p = await addImage({
        file: e.target.files,
        type: "multi",
        folders: 'returnorder',
      });
      returnProduct.value.items.find(x => x.position == viewFotoAndComment.value.id).images.push(...p)
};

const viewCommentSmodel = () => returnProduct.value.items.find(x => x.position == viewFotoAndComment.value.id).comments

const proverKas = (id) => {
    if(returnProduct.value.items.find(x=> x.position == id))return true
    return false
}
const addRetun = (one) => {

    if(returnProduct.value.items.find(x=> x.position == one._id)){
        returnProduct.value.items = returnProduct.value.items.filter(x => x.position !== one._id)
        return
    }
    returnProduct.value.items.push({
        guid:one.guid,
        name:one.name,
        position:one._id,
        productid:one.productid._id,
        specification:one.specification,
        images:[],
        price:one.price,
        status:'new',
        quality:'new',
        quantity:1,
        comments:'',
        payment:one.payment,
        cashback:one.cashback
    })
}
const kVozvraty = () => {
    let ayP = []
    var bonus = 0
    for(let one of returnProduct.value.items){
       bonus = bonus + one.cashback.remove_bonus
       for(let p of one.payment){
        if(ayP.find(x=> x.entity == p.entity && x.service == p.service)){
            var ms = ayP.find(x=> x.entity == p.entity && x.service == p.service)
            ms.price = Number(ms.price) + Number(p.price*Number(one.quantity))
        }else{
            var zp = newObject(p)
            zp.price = Number(zp.price)*Number(one.quantity)
            ayP.push(zp)
        }
      }
    }
    if(bonus > 0){
        ayP.push({
            service:'bonus',
            price:bonus,
            entity:'none'
        })

    }
    return ayP
}
</script>

<style scoped>
.top{
    margin-top:20px;
}
.blockRetunProduct{
    display: grid;
    gap:10px;
}
.oneProduct{
    width: calc(100% - 20px);
    background: var(--white);
    padding:10px;
    display: grid;
    grid-template-columns: auto 1fr;
    gap:10px;
    align-items: center;
}
.oneProduct img{
    width: 100px;
    height: 100px;
    object-fit: contain;
}
.activityblock{
    background: var(--black);
}
.activityblock .productName p{
    color: var(--white);
}
.menuRetun{
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
    background: var(--white);
    padding: 5px;
    width: calc(100% - 10px);
}
.menuRetun .inputtitle p {
    
}
.menuRetun .inputtitle select{
    width: 100%;
}
.itogiVozvrata{
    padding: 10px;
    width: calc(100% - 20px);
    background: var(--white);
}
.comments{
    margin-top:40px;
}
.comments textarea {
    padding: 10px;
width: calc(100% - 25px);
min-height: 150px;
margin-bottom: 20px;
}

.textVozvrat{
    display: grid;
    grid-template-columns: 1fr auto;
    gap:5px;
}
.twoBlockIngos{
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    background: var(--black);
}
.nameChannel{
    color: var(--white);
    padding: 5px;
    display: grid;
    align-items: center;
    justify-items: center;
}
.addPhotos{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    gap: 10px;
    margin-top:20px;
}
.onePhotoAdd{
    border: 2px dashed var(--blackgrey);
    width: 100%;
    display: grid;
    min-height: 250px;
    font-size: 40px;
    font-weight: 100;
    font-family: 'conke';
    align-items: center;
    justify-items: center;
}
.addPhotos label{
    width: 100%;
}
.onePhoto{
    width: 100%;
    display: grid;
    height: 100%;
    grid-template-rows: 1fr 20px;
    text-align: center;
    background: var(--grey);
}
.onePhoto img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.photoDelete{
    padding:5px;
}

.twoblocks{
    display: grid;
    grid-template-columns: auto 1fr;
    width: max-content;
    gap: 10px;
    align-items: center;
    width: 100%;
}
.twoblocks p {
    text-align: center;
    font-weight: 200;
    color: var(--black);
    font-size: 18px;
    text-align: right;
}
.blockUpravlenie{
    margin-top:20px;
}
.buttonsdown{
    margin-bottom: 80px;
}
</style>
