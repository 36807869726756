<template>
    <div class="clientInfos">
        <div class="avatars">
                <img :src="props.c.avatar ? `https://api.ultimatum.store/images/${props.c.avatar}?quality=80&width=160&format=jpeg` : '/img/nophoto.jpg'">
        </div>
        <div class="nameAndFamilia">
            
                <p>{{ props.c.firstname ? props.c.firstname : 'Нет'}} {{ props.c.lastname ? props.c.lastname : 'Нет'}}</p>
                <a :href="`tel:${props.c.phone}`">{{ props.c.phone }}</a>
        </div>

    </div>
</template>

<script setup>
const props = defineProps(['c'])
</script>

<style scoped>
.avatars{
    display: grid;
    align-items: center;
    justify-items: center;
}
.avatars img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
}
.clientInfos{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    align-items: center;
    justify-items: center;
    background: var(--white);
    padding: 10px;
    width: calc(100% - 20px);
}
</style>