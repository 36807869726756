import { createRouter, createWebHistory } from 'vue-router'
import { updateLogin, proverkaCookies, activity } from '@/controlles/auth'

import general from './general'
import catalog from './сatalog'
import userview from './user'
import client from './client'

const routes = [
  ...catalog,
  ...general,
  ...userview,
  ...client
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  if (!activity.value) {
    if (proverkaCookies()) {
      try {
        await updateLogin()
        return true
      } catch (e) {
        return { name: 'auth' }
      }
    }
    if (to.meta.auth) { return true }
    return { name: 'auth' }
  }
})

export default router
