export default [
  {
    path: '/user/profile',
    name: 'user-profile',
    component: () => import('../views/user/profile.vue')
  },
  {
    path: '/user/task',
    name: 'user-task',
    component: () => import('../views/user/task.vue')
  },
  {
    path: '/user/help',
    name: 'user-help',
    component: () => import('../views/user/help.vue')
  }

]
