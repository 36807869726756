<template>
       <div class="okno_full" >
                    <div class="headnot">
                      <h5  @click="close()">Закрыть</h5>
                      <img :src="client.avatar !== null ? 'https://api.ultimatum.store/images/' + client.avatar : '/img/nophoto.jpg'" @click="oknoUser(true)" class="ava">
                      <div class="catalog" @click="goTocatalog()">КАТАЛОГ</div>
                      <div class="menuBasket" @click="scrolscaner()">
                        <div class="tovarod">

                        </div>
                        <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><path d="M224,40H184a8,8,0,0,0,0,16h32V88a8,8,0,0,0,16,0V48A8,8,0,0,0,224,40Z"/><path d="M72,200H40V168a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H72a8,8,0,0,0,0-16Z"/><path d="M224,160a8,8,0,0,0-8,8v32H184a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V168A8,8,0,0,0,224,160Z"/><path d="M32,96a8,8,0,0,0,8-8V56H72a8,8,0,0,0,0-16H32a8,8,0,0,0-8,8V88A8,8,0,0,0,32,96Z"/><path d="M80,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,80,80Z"/><path d="M184,168V88a8,8,0,0,0-16,0v80a8,8,0,0,0,16,0Z"/><path d="M144,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,144,80Z"/><path d="M112,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,112,80Z"/></svg>
                      </div>

                    </div>
                    <div class="conents_okno" ref="conNT">
             <Suspense>
                     <scanner @barcode="getProductBarcode" :format="'ean_13'"/>
             </Suspense>

        <div class="fulls" v-if="basket.length > 0">
        <div class="basketProduct">

            <itembasket v-for="(p,index) of basket" :p="p" :key="p.productid + index" :in="index"/>
           
        </div>

        <div v-show="order.nextordergo">
        <div class="fishkiblock">
            <Suspense><fishki /></Suspense>
        </div>
         <div class="fishkiblock" style="margin-top: 20px;">
           <referral />
        </div>
        <div class="blockDeystvie payment">
          <p class="vIps">Способ покупки:</p>
          <div class="twoblocks">
                        <div class="customCheckbox" :class="{customCheckboxActivity:sposobPokupki.shop}"
                        @click="changeFun('shop',sposobPokupki)"></div>
                        <p>В магазине</p>
                        </div>
          
          <div class="twoblocks">
                        <div class="customCheckbox" :class="{customCheckboxActivity:sposobPokupki.pickup}"
                        @click="changeFun('pickup',sposobPokupki)"></div>
                        <p>Самовывоз из магазина</p>
                        </div>
          </div>
        <transition name="slimeright">
        <div class="payment" v-if="sposobPokupki.shop">
            <div class="typeoplate" v-for="one of viewPayment">
              <p>{{one.name}}</p>
              <input
              type="tel"
              pattern="[0-9]*"
              @input="addPayment($event,one.type,'focus')" :value="one.price" @focus="addPayment($event,one.type,'focus')" @blur="addPayment($event,one.type,'blur')">
            </div>
        </div>
        </transition>
        <transition name="slimeright">
        <div class="payment" v-if="sposobPokupki.pickup">
           <p>Оплата:</p>
           <div class="twoblocks">
                        <div class="customCheckbox" :class="{customCheckboxActivity:piakUpPayment['bank-card']}"
                        @click="changeFun('bank-card',piakUpPayment)"></div>
                        <p>Банковской картой</p>
                        </div>
          <div class="twoblocks">
                        <div class="customCheckbox" :class="{customCheckboxActivity:piakUpPayment['dolyamy']}"
                        @click="changeFun('dolyamy',piakUpPayment)"></div>
                        <p>Рассрочкой долями</p>
                        </div>
          <div class="twoblocks">
                        <div class="customCheckbox" :class="{customCheckboxActivity:piakUpPayment['cash']}"
                        @click="changeFun('cash',piakUpPayment)"></div>
                        <p>Наличными</p>
                        </div>
        </div>
        </transition>
        <div class="good">

          <transition name="list">
                <div class="sdacha" v-if="sdacha > 0">
                  <p>СДАЧА КЛИЕНТУ:</p><span>{{ sdacha.toLocaleString('ru-RU') }} ₽</span>
                </div>
          </transition>
        </div>
        <transition name="list">
        <div class="good payment" v-if="viewButtonOplate">
                <div class="blockDeystvie">
                  <p class="vIps">Выполнить</p>
                  <div class="twoblocks">
                        <div class="customCheckbox" :class="{customCheckboxActivity:ds.all}"
                        @click="ds.all = !ds.all"></div>
                        <p>Оплачен и выдан</p>
                  </div>

                  <div class="twoblocks">
                        <div class="customCheckbox" :class="{customCheckboxActivity:ds.oplata}"
                        @click="ds.oplata = !ds.oplata"></div>
                        <p>Оплачен</p>
                  </div>

                  <div class="twoblocks">
                        <div class="customCheckbox" :class="{customCheckboxActivity:ds.vydat}"
                        @click="ds.vydat = !ds.vydat"></div>
                        <p>Выдан</p>
                  </div>

                  <div class="twoblocks">
                        <div class="customCheckbox" :class="{customCheckboxActivity:ds.sponsor}"
                        @click="ds.sponsor = !ds.sponsor"></div>
                        <p>Спонсорство</p>
                  </div>

                  <div class="twoblocks">
                        <div class="customCheckbox" :class="{customCheckboxActivity:ds.save}"
                        @click="ds.save = !ds.save"></div>
                        <p>Сохранить</p>
                  </div>

                </div>
                <transition name="list">
                <div class="button nocopy" @click="goToSale()" v-if="Object.entries(ds).find(x => x[1] == true)">Готово</div>
                </transition>
               
        </div>
        </transition>

        <transition name="list">
                 <div class="button nocopy" @click="goToSale()" v-if="sposobPokupki.pickup">Готово</div>
        </transition>

        </div>

        <p v-show="!order.nextordergo" style="text-align: center;margin: 20px 0px;font-weight: 300;">
          В корзине есть товар с ЧЕСТНЫМ ЗНАКОМ. Отсканируйте его
        </p>

        </div>
    </div>
    </div>

    <transition name="slimeright">
      <div class="infoForSamovyvoz" v-if="infoSamovyvoz.view">
        <div class="blockInfoLoadingSamo">
              <div class="oneInfoSamo">
                <span class="loader" v-if=!infoSamovyvoz.order></span>
                <svg v-else height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg"><path class="cls-1" d="M800,510a30,30,0,1,1,30-30A30,30,0,0,1,800,510Zm-16.986-23.235a3.484,3.484,0,0,1,0-4.9l1.766-1.756a3.185,3.185,0,0,1,4.574.051l3.12,3.237a1.592,1.592,0,0,0,2.311,0l15.9-16.39a3.187,3.187,0,0,1,4.6-.027L817,468.714a3.482,3.482,0,0,1,0,4.846l-21.109,21.451a3.185,3.185,0,0,1-4.552.03Z" id="check" transform="translate(-770 -450)"/></svg>
                <p v-if="infoSamovyvoz.ordernumber == null">Создаем заказ</p>
                <p v-else>Номер заказа {{infoSamovyvoz.ordernumber}}</p>
              </div>
              <div class="oneInfoSamo">
                <span class="loader" v-if=!infoSamovyvoz.payment></span>
                <svg v-else height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg"><path class="cls-1" d="M800,510a30,30,0,1,1,30-30A30,30,0,0,1,800,510Zm-16.986-23.235a3.484,3.484,0,0,1,0-4.9l1.766-1.756a3.185,3.185,0,0,1,4.574.051l3.12,3.237a1.592,1.592,0,0,0,2.311,0l15.9-16.39a3.187,3.187,0,0,1,4.6-.027L817,468.714a3.482,3.482,0,0,1,0,4.846l-21.109,21.451a3.185,3.185,0,0,1-4.552.03Z" id="check" transform="translate(-770 -450)"/></svg><p>Создаем оплату</p>
              </div>
              <div class="oneInfoSamo">
                <span class="loader" v-if=!infoSamovyvoz.dotavka></span>
                <svg v-else height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg"><path class="cls-1" d="M800,510a30,30,0,1,1,30-30A30,30,0,0,1,800,510Zm-16.986-23.235a3.484,3.484,0,0,1,0-4.9l1.766-1.756a3.185,3.185,0,0,1,4.574.051l3.12,3.237a1.592,1.592,0,0,0,2.311,0l15.9-16.39a3.187,3.187,0,0,1,4.6-.027L817,468.714a3.482,3.482,0,0,1,0,4.846l-21.109,21.451a3.185,3.185,0,0,1-4.552.03Z" id="check" transform="translate(-770 -450)"/></svg><p>Создаем самовывоз</p>
              </div>
              <div class="oneInfoSamo">
                <span class="loader" v-if=!infoSamovyvoz.sms></span>
                <svg v-else height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg"><path class="cls-1" d="M800,510a30,30,0,1,1,30-30A30,30,0,0,1,800,510Zm-16.986-23.235a3.484,3.484,0,0,1,0-4.9l1.766-1.756a3.185,3.185,0,0,1,4.574.051l3.12,3.237a1.592,1.592,0,0,0,2.311,0l15.9-16.39a3.187,3.187,0,0,1,4.6-.027L817,468.714a3.482,3.482,0,0,1,0,4.846l-21.109,21.451a3.185,3.185,0,0,1-4.552.03Z" id="check" transform="translate(-770 -450)"/></svg><p>Отправляем смс</p>
              </div>
           
              <div class="button nocopy" @click="infoSamovyvoz.view = false">Закрыть</div>
        </div>
      </div>



    </transition>
</template>

<script setup>
import itembasket from '@/components/product/itembasket.vue'
import fishki from '@/components/client/fishki.vue'
import scanner from '@/components/general/scanner.vue'
import referral from '@/components/client/referral.vue'
import { basket, order, sendNewOrder, createPayment, deleteBasket, viewbasket, removePayment, clearFishki,createDelivery } from '@/controlles/basket/basket'
import axios from '@/utils/axios'
import { notSet, setSuc, loading,noloading } from '@/controlles/general'
import { prototypeItem } from '@/controlles/basket/itemPrototype'
import { computed, ref, onMounted, reactive, watch } from 'vue'
import { client, updateLogin, clearUser, oknoUser } from '@/controlles/client'
import { useRouter } from 'vue-router'
    const r = useRouter()
    const ds = reactive({
      oplata: false,
      vydat: false,
      save: false,
      all: false,
      sponsor: false
    })

    const sposobPokupki = reactive({
      shop:true,
      pickup:false
    })
    const piakUpPayment = reactive({
      'bank-card':true,
      'dolyamy':false,
      'cash':false
    })

    const infoSamovyvoz = reactive({
      view:false,
      order:false,
      payment:false,
      dotavka:false,
      sms:false,
      ordernumber:null
    })
    const changeFun = (key,arr) => {
      for (const prop in arr) {
        arr[prop] = false;
      }
      arr[key] = true;
    };


    const goTocatalog = () => {
      if (window.localStream) { window.localStream.getTracks().forEach(track => track.stop()) }
      r.push('/catalog/')
       viewbasket.value = false
    }

    const close = () => {
      if (window.localStream) { window.localStream.getTracks().forEach(track => track.stop()) }
      goTocatalog()
    }
    const sdacha = ref(0)
    const conNT = ref(null)
    const viewPayment = computed(() => {
      if (!order.markingorder) { return order.paymentorder.type.filter(x => x.entity == 'podgornov') }
      return order.paymentorder.type.filter(x => x.entity == 'damanina')
    })

    const viewButtonOplate = computed(() => {
      if (ds.sponsor) { return true }
      const cash = viewPayment.value.find(x => x.type == 'cash')
      if (cash.activity) {
        if (cash.price > order.paymentorder.payment) {
          sdacha.value = cash.price - order.paymentorder.payment
        } else {
          sdacha.value = 0
        }

        const bank = viewPayment.value.find(x => x.type == 'bank-card')
        if (bank.activity) {
          if ((cash.price + bank.price) > order.paymentorder.payment) {
            sdacha.value = cash.price - (order.paymentorder.payment - bank.price)
          } else {
            sdacha.value = 0
          }
        }
      }
      if (viewPayment.value.reduce((a, x) => a + x.price, 0) == (order.paymentorder.payment + sdacha.value)) {
        return true
      }

      return false
    })

    const goToSale = async () => {
      try {
        if(sposobPokupki.shop){loading.value = true}
        var channe = 'ultimatum-shop'
        if(sposobPokupki.pickup){
          channe = 'ultimatum-arm'
          noloading.value = true
          infoSamovyvoz.view = true
        }
        const { orderID, orderNumber } = await sendNewOrder(channe)
       
        if(sposobPokupki.pickup){
          infoSamovyvoz.ordernumber = orderNumber
           await createDelivery(orderID)
          infoSamovyvoz.order = true
          infoSamovyvoz.dotavka = true
          await createPayment(orderID, orderNumber,0,true,Object.entries(piakUpPayment).find(x => x[1] == true)[0])
          infoSamovyvoz.payment = true
          if(Object.entries(piakUpPayment).find(x => x[1] == true)[0] !== 'cash'){
          await axios.post(`https://api.ultimatum.store/sms/send`,{phone:client.value.phone,text:`Перейдите по ссылке для оплаты вашего заказа:https://ultimatum.store/pmt?id=${orderNumber}`})
          }
          infoSamovyvoz.sms = true
          deleteBasket()
          noloading.value = false
          return
        }

        if (ds.oplata) {
          await createPayment(orderID, orderNumber, sdacha.value)
          await axios.post('https://api.ultimatum.store/order/sendcrm', { id: orderID })
        }
        if (ds.vydat) {
          await axios.post('https://api.ultimatum.store/bd/updatedocument', { collection: 'order', _id: orderID, set: { $set: { status: 'successfully' } } })
          await axios.post('https://api.ultimatum.store/crm/successfullyshop', { id: orderID })
          deleteBasket()
          setSuc('Заказ оформлен', 'good')
        }
        if (ds.save) {
          await axios.post('https://api.ultimatum.store/order/sendcrm', { id: orderID })
          deleteBasket()
          setSuc('Заказ сохранен', 'good')
        }
        if (ds.sponsor) {
          await axios.post('https://api.ultimatum.store/bd/updatedocument', { collection: 'order', _id: orderID, set: { $set: { sponsorship: true } } })
          await axios.post('https://api.ultimatum.store/order/sendcrm', { id: orderID })
          await axios.post('https://api.ultimatum.store/bd/updatedocument', { collection: 'order', _id: orderID, set: { $set: { status: 'successfully' } } })
          await axios.post('https://api.ultimatum.store/crm/successfullyshop', { id: orderID })
          deleteBasket()
          setSuc('Заказ оформлен', 'good')
        }

        ds.oplata = false
        ds.vydat = false
        ds.save = false
        ds.sponsor = false
        await updateLogin(client.value.phone, 'phone')
        loading.value = false
      } catch (e) {
        if(sposobPokupki.shop){
        setSuc('Ошибка оформления', 'error')
        }
        noloading.value = false
        console.log(e)
      }
    }

    const addPayment = (e, p, type) => {
      sdacha.value = 0
      const val = Number(e.target.value) - sdacha.value
      const pa = viewPayment.value.find(x => x.type == p)
      const summ = viewPayment.value.reduce((a, x) => a + x.price, 0)
      if (val < 0) {
        pa.price = 0
        return
      }
      const patwo = viewPayment.value.find(x => x.type !== p)
      const summmy = order.paymentorder.payment - patwo.price - sdacha.value
      if (type == 'focus') {
        if (summ == 0) {
          pa.price = summmy
          return
        } else {
          if (summmy !== 0) {
            pa.price = summmy
            return
          }
        }
      }
      if (type == 'blur') {
        if (val < 0) {
          pa.price = 0
          return
        }
        if (summmy == 0) {
          pa.price = 0
          return
        }
        pa.price = val - sdacha.value
      }
    }

    const scrolscaner = () => { conNT.value.scrollIntoView() }

    const getProductBarcode = async (barcode) => {
      try {
        const otvet = await axios.post('https://api.ultimatum.store/arm/shop/barcode', { barcode: barcode })
        if (otvet.status == 'error') {
          notSet('Штрихкод не распознан')
          return
        }

        if (otvet.spec == null) {
          var pr = new prototypeItem(otvet.product._id, otvet.product.activityspecifications, otvet.product)
          pr.addToCard()
        }

        if (otvet.spec !== null) {
          var pr = new prototypeItem(otvet.product._id, otvet.product.activityspecifications, otvet.product, otvet.spec)
          pr.addToCard()
        }

        notSet('Товар добавлен')
      } catch (e) {
        console.log(e)
      }
    }

    const clearPrice = () => {
      removePayment()
    }

    watch(ds, () => {
      if (ds.all) {
        ds.oplata = true
        ds.vydat = true
        ds.save = false
      }

      if (ds.save) {
        ds.oplata = false
        ds.vydat = false
        ds.all = false
      }
      if (ds.oplata) {
        ds.save = false
      }
      if (ds.vydat) {
        ds.save = false
      }
      if (ds.sponsor) {
        ds.oplata = false
        ds.vydat = false
        ds.all = false
        ds.save = false
        clearPrice()
      }
    })

    watch(basket,() => {clearPrice()})
    onMounted(() => clearPrice(),clearFishki())
    
</script>

<style scoped>

.catalog{
    color: var(--white);
    font-weight: 300;
}
.headnot{
  z-index: 9;
  grid-template-columns:1fr auto auto auto;
}
.ava{
    width: 40px;
    height: 40px;
    border-radius: 100%;
}
.fishkiblock{
    background: #fff;
    padding: 5px;
    width: calc(100% - 10px);
}

.payment{
  background: #fff;
    padding: 5px;
    width: calc(100% - 10px);
    margin-top:20px;
}

.typeoplate{

}
.typeoplate p {
    font-weight: 300;
    color: var(--black);
    font-size: 18px;
    margin:10px 0px;
}

.typeoplate input{
  font-size: 18px;
}

.typeoplate{}

.button{
  margin-top:20px;
}

.sdacha{
    display: grid;
    grid-template-columns: 1fr auto;
    width: calc(100% - 20px);
    margin: 0 auto;
    background: var(--white);
    padding: 10px;

}

.sdacha p, .sdacha span{
  font-weight: 300;
  font-size: 18px;
}

.sdacha span{
color: var(--red);
}

.menuBasket{

}

.menuBasket svg{
  width: 30px !important;
}

.menuBasket svg path{

}

.conents_okno{
  padding-bottom: 100px;
}

.blockDeystvie{}
.blockDeystvie p {}


.twoblocks{
    display: grid;
    grid-template-columns: auto 1fr;
    width: max-content;
    gap: 10px;
    align-items: center;
    width: 100%;
}
.twoblocks p {
    text-align: center;
    font-weight: 200;
    color: var(--black);
    font-size: 18px;
    text-align: right;
}
.vIps{
   font-weight: 300;
    color: var(--black);
    font-size: 18px;
    margin: 10px 0px;
}

.infoForSamovyvoz{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--white);
    z-index: 9999999999999;
    display: grid;
    align-items: center;
    justify-items: center;
}
.blockInfoLoadingSamo{

}
.loader {
    width: 25px;
    height: 25px;
    border: 2px solid var(--lightblue);
    border-bottom-color: var(--black);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation-fb4d68ac 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.oneInfoSamo{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    margin-bottom: 10px;
} 

.oneInfoSamo svg{
  width: 25px;
    height: 25px;
}

.blockInfoLoadingSamo .button{
  margin-top:20px;
}
.cls-1 {
        fill: var(--black);
        fill-rule: evenodd;
      }
</style>
