const bookPay = {
    'bank-card':'Банковская карта',
    'cash':'Наличные средства',
    'ivanytina':'Иванютина',
    'podgornov':'Подгорнов',
    'ultimatum-shop':'Магазин',
    'ultimatum-store':'Сайт RU',
    'ultimatum-com':'Сайт COM',
    'onevision':'Onevision',
    'dolyamy':'Долями',
    'payment-upon-receipt':'При получении',
    'return-advance':'Аванс',
    'endorsement-deposit':'Ultimatum Select',
    'none':'Клиент',
    'bonus':'Бонусы',
    'ultimatum-crm':'СRM'

}

export {
    bookPay
}