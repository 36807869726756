<template>

    <div class="item">

            <div class="titles">{{viewproduct.name}}<br><span>({{ viewproduct.artikul }})</span></div>
            <div class="chestznak" v-if="viewproduct.marking">
                <div class="cheststatus">
                    <p>Честный знак:</p><p>{{viewproduct.marking_code == null ? 'Не отсканирован' : viewproduct.marking_code_decode.substring(0, 31) }}</p>
                </div>
                <div class="button clickbutton" @click="viewScaner = true">{{ viewproduct.marking_code == null ? 'Отсканировать' : 'Изменить' }}</div>
            </div>
            <select  @change="updateSpecification" v-if="viewproduct.dataproduct.activityspecifications">
                        <option value="0">не выбран</option>
                        <option
                        v-for="spec of viewproduct.dataproduct.specifications"
                        :key="spec._id"
                        :value="spec._id" :selected="viewproduct.specification == spec._id"
                        :disabled="!viewproduct.proverkaProdazhi(spec._id)"
                        >{{ spec.name }}</option>

            </select>

            <div class="blockleft">

                <img :src="`https://api.ultimatum.store/image/${viewproduct.dataproduct.images[0]}?quality=100&width=160&format=jpeg`">
            </div>
            <div class="blockright">
                <div class="price">
                 <input type="number" v-model="viewproduct.newprice">
                    <h3>{{viewproduct.price.toLocaleString('ru-RU')}} ₽ <span v-if="viewproduct.discount_procent !== 0" class="priceDelete">{{viewproduct.realprice.toLocaleString('ru-RU')}} ₽</span></h3>
                </div>
                <div class="quantity" v-if="viewproduct.balance !== 0">
                    <div class="button clickbutton" @click="viewproduct.quantityChange('minus')">-</div>
                    <div class="itemquantity">{{ viewproduct.quantity }}</div>
                    <div class="button clickbutton" :style="{opacity: viewproduct.blockadditem.value ? 0.5 : 1}" @click="viewproduct.quantityChange('add')">+</div>
                </div>

               <div class="button clickbutton"  v-if="viewproduct.balance.value !== 0" @click="deleteProduct(ind,viewproduct.timeadd)">Удалить</div>

            </div>

        </div>

                <transition name="slimetop">
                    <div class="blockscanner" v-if="viewScaner">
                            <p @click="viewScaner = false">Закрыть</p>
                            <Suspense>
                                <scanner :format="'data_matrix'" @barcode="getDataMatrix"/>
                            </Suspense>
                        </div>
                </transition>

                <transition name="slimetop">
                    <div class="getZnak" v-if="getZnak.view">
                        <div class="infoBlockGet">
                           <div class="information">
                                <span class="loader" v-if="getZnak.loading"></span>
                                <p class="good" v-if="!getZnak.loading && getZnak.good">Честный знак одобрен</p>
                                <p class="error" v-if="!getZnak.loading && !getZnak.good">Ошибка одобрения</p>
                                <p>{{ getZnak.comment }}</p>

                           </div>
                           <div class="button clickbutton" @click="getZnak.view = false">Закрыть</div>
                        </div>
                    </div>
                </transition>

</template>

<script setup>
import { deleteProduct } from '@/controlles/basket/basket'
import { ref } from 'vue'
import axios from '@/utils/axios'
import { noloading } from '@/controlles/general'
import scanner from '@/components/general/scanner.vue'

const props = defineProps(['p', 'in'])
const ind = props.in
const viewproduct = props.p
const getZnak = ref({
  view: false,
  comment: '',
  loading: true,
  good: false,
  comment: 'Проверяем данные честного знака'
})
const updateSpecification = (e) => { viewproduct.specification = e.target.value }
const viewScaner = ref(false)
const getDataMatrix = async (data) => {
  noloading.value = true
  viewproduct.marking_code = window.btoa(unescape(encodeURIComponent(data)))
  viewproduct.marking_code_decode = data
  getZnak.value.view = true
  getZnak.value.loading = true

  const getZnakData = await axios.post('https://api.ultimatum.store/arm/znak/check', { marking: data })
  if (getZnakData.status == 'good') {
    viewproduct.marking_uuid = getZnakData.uuid
    getZnak.value.good = true
    getZnak.value.loading = false
    getZnak.value.comment = ''
    viewScaner.value = false
  }
  if (getZnakData.status == 'error') {
    getZnak.value.good = false
    getZnak.value.loading = false
    getZnak.value.comment = getZnakData.comment
  }
  noloading.value = false
}

</script>

<style scoped>
.item{
    padding: 10px;
    width: calc(100% - 20px);
    background: var(--white);
    margin: 10px 0px;
    box-shadow: var(--boxshadowlight);
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px;
}
.blockleft{
    width: 160px;
    height: 160px;
}
.blockleft img {
    width: 160px;
    height: 160px;
    object-fit: contain;
}

.blockright{

}
.titles{
    grid-column: span 2;
    margin-bottom: 5px;
    font-weight: 200;
}

select{
    width: 100%;
    grid-column: span 2;
    text-align: center;
    padding: 10px;
}
.quantity{
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    align-items: center;
    justify-items: center;
    margin-bottom: 10px;
}

.button{
    min-height: calc(60px - 20px);
    padding: 5px;
}

.quantity .button{

    width: calc(100% - 10px);
    background: none;
    color: var(--black);
    font-size: 34px;
    font-weight: 100;

}

.quantity .itemquantity{
    font-size: 28px;
    font-weight: 300;
}

.price{
    display: grid;
   
    margin-bottom: 10px;
    gap:5px;
}
.priceDelete{
    font-size: 20px;
    opacity: 0.5;
    text-decoration: line-through;

}

.chestznak{
    grid-column: span 2;
    border: 1px solid var(--blackgrey);
}

.cheststatus{
    grid-template-columns: 120px 1fr;
    display: grid;
    text-align: right;
    align-items: center;
    margin: 10px 0px;
    padding: 0px 10px;
    width: calc(100% - 20px);
    gap: 10px;
}

.cheststatus p {
    font-size: 18px;
    font-weight: 300;
    word-break: break-all;
}

.blockscanner{
    position: fixed;
    width: 100%;
    height: 100vh;
    display: grid;
    top: 0;
    left: 0;
    background: var(--black);
    z-index: 20;
}

.blockscanner p {
    color: var(--white);
    font-weight: 300;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 20px;
    margin-left: 20px;
}

.getZnak{
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    height: 100vh;
    background: var(--white);
    z-index: 999;
    padding: 20px;
    display: grid;
    align-items: center;
}
.loader {
    width: 25px;
    height: 25px;
    border: 2px solid var(--lightblue);
    border-bottom-color: var(--black);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation-fb4d68ac 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.information{
    display: grid;
    gap: 10px;
    text-align: center;
    justify-items: center;
    margin-bottom: 40px;
}
.good{
    color: var(--green);
}
.error{
    color: red;
}
</style>
